import axios from "axios"
import config from "@/config"
import qs from 'querystring';
import Vue from 'vue';
import {Toast} from 'vant';
import router from "../router";

Vue.use(Toast);

const baseUrl = process.env.NODE_ENV === 'development' ? config.baseUrl.dev : config.baseUrl.pro;

class HttpRequest {
    constructor(baseUrl) {
        this.baseUrl = baseUrl
    }

    getInsideConfig() {
        const config = {
            baseURL: this.baseUrl,
            header: {}
        }
        return config
    }

    interceptors(instance) {
        instance.interceptors.request.use((config) => {
            Toast.loading({
                message: '加载中...',
                forbidClick: true,
            });
            config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
            let token = localStorage.getItem("token");
            if (config.method === 'post') {
                config.data = qs.stringify(config.data);
            }
            return config
        })
        instance.interceptors.response.use((res) => {
            Toast.clear();
            //处理响应
            return res.data
        }, (error) => {
            if (error.response.status == 401) {
                Toast({
                    type: "fail", message: "您的登录已过期，请重新登录", onClose: () => {
                        router.replace("Login");
                    }
                });
            } else {
                console.log(error)
                return {error: "网络出错了"}
            }
        })
    }

    request(options) {
        const instance = axios.create()//创造实例对象
        options = Object.assign(this.getInsideConfig(), options)
        this.interceptors(instance, options.url)
        return instance(options)
    }
}

const axiosObj = new HttpRequest(baseUrl)
export default axiosObj
