import axios from '@/api/axios';

//首页
export const _getHome = (data) => {
    return axios.request({url: "workapi/mobile.Index/get_user", method: 'post', data: data})
};

//统计页1
export const _getStatistics = (data) => {
    return axios.request({url: "workapi/mobile.Statistics/index", method: 'post', data: data})
};


//统计页1
export const _getGroupStatistics = (data) => {
    return axios.request({url: "workapi/mobile.Statistics/user_group_chart", method: 'post', data: data})
};

// 客户列表
export const _userList = (data) => {
    return axios.request({url: "workapi/mobile.User/index", method: 'post', data: data})
};

// 素材类型
export const _materialType = (data) => {
    return axios.request({url: "workapi/mobile.material/material_type", method: 'post', data: data})
};

// 素材列表
export const _getMaterial = (data) => {
    return axios.request({url: "workapi/mobile.material/index", method: 'post', data: data})
};

//我的客户群列表
export const _getAllGroup = (data) => {
    return axios.request({url: "workapi/mobile.Qrcode/get_all_group", method: 'post', data: data})
};

//群活码列表
export const _getQrCodeList = (data) => {
    return axios.request({url: "workapi/mobile.Qrcode/index", method: 'post', data: data})
};

// 我的群列表
export const _groupList = (data) => {
    return axios.request({url: "workapi/mobile.Qrcode/get_all_group", method: 'post', data: data})
};

//创建群活码
export const _addQrcode = (data) => {
    return axios.request({url: "workapi/mobile.Qrcode/add_qrcode", method: 'post', data: data})
};


//30天的日期
export const _getDayList = (data) => {
    return axios.request({url: "workapi/mobile.Statistics/day_list", method: 'post', data: data})
};

// 每日统计
export const _getDay = (data) => {
    return axios.request({url: "workapi/mobile.Statistics/day_chart", method: 'post', data: data})
};


// 员工信息
export const _userInfo = (data) => {
    return axios.request({url: "workapi/mobile.Index/user_detail", method: 'post', data: data})
};

// 会话接口配置参数
export const _getConfig = (data) => {
    return axios.request({url: "workapi/mobile.Chat/get_config", method: 'post', data: data})
};


// 客户详情
export const _getUserDetail = (data) => {
    return axios.request({url: "workapi/mobile.User/user_detail", method: 'post', data: data})
};

// 标签列表
export const _getLabelList = (data) => {
    return axios.request({url: "workapi/mobile.User/tag_list", method: 'post', data: data})
};

// 添加标签
export const _addLabel = (data) => {
    return axios.request({url: "workapi/mobile.User/add_tag", method: 'post', data: data})
};

// 编辑标签
export const _saveLabel = (data) => {
    return axios.request({url: "workapi/mobile.User/edit_user_tag", method: 'post', data: data})
};

// 客户动态
export const _getDynamic = (data) => {
    return axios.request({url: "workapi/mobile.User/dynamic_log", method: 'post', data: data})
};

// 客户跟进方式
export const _getFollow  = (data) => {
    return axios.request({url: "workapi/mobile.User/get_follow_type", method: 'post', data: data})
};

//跟进列表
export const _follow_list  = (data) => {
    return axios.request({url: "workapi/mobile.User/follow_list", method: 'post', data: data})
};


// 图片上传
export const _uploadImg  = (data) => {
    return axios.request({url: "workapi/mobile.User/upload", method: 'post', data: data})
};

// 添加跟进
export const _addFollow  = (data) => {
    return axios.request({url: "workapi/mobile.User/add_follow", method: 'post', data: data})
};


// 任务列表
export const _taskIndex  = (data) => {
    return axios.request({url: "workapi/mobile.Task/index", method: 'post', data: data})
};

// 人员列表
export const _peopleList  = (data) => {
    return axios.request({url: "workapi/mobile.Task/chooseuser", method: 'post', data: data})
};

// Task/addtask  添加任务接口
export const _addTask  = (data) => {
    return axios.request({url: "workapi/mobile.Task/addtask", method: 'post', data: data})
};

// 任务详情
export const _taskDetail  = (data) => {
    return axios.request({url: "workapi/mobile.Task/taskdetail", method: 'post', data: data})
};

// 完成任务
export const _overtask  = (data) => {
    return axios.request({url: "workapi/mobile.Task/overtask", method: 'post', data: data})
};

// 提醒
export const _messWorkList  = (data) => {
    return axios.request({url: "workapi/mobile.Index/mess_work_list", method: 'post', data: data})
};

// 话术列表
export const _speechcraftList  = (data) => {
    return axios.request({url: "workapi/mobile.Speechcraft/index", method: 'post', data: data})
};

// 添加话术
export const _addScript  = (data) => {
    return axios.request({url: "workapi/mobile.Speechcraft/add", method: 'post', data: data})
};

// 个人上传素材
export const _materialUpload  = (data) => {
    return axios.request({url: "workapi/mobile.Material/upload", method: 'post', data: data})
};
